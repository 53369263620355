
import { delMyQrCss, getQrBase64, getQrStyleList, qrMyCssList, qrPubCssTempList, saveMyQrCss } from '@/api/template'
import eventBus from '@/utils/event-bus'

export default {
  name : 'BeautifyQr',
  components : {},
  props : {
    baseImg : String,
    qrText : String
  },
  data() {
    return {
      idisCode : '',
      eyeModels : [
        {
          chose : true,
          src : require( '../../assets/images/eyeModels/eye0.png' ),
          name : 'EYE_DEFAULT'
        },
        {
          chose : false,
          name : 'SQUARE2',
          src : require( '../../assets/images/eyeModels/eye5.png' )
        },
        {
          chose : false,
          name : 'SQUARE3',
          src : require( '../../assets/images/eyeModels/eye6.png' )
        },
        {
          chose : false,
          name : 'SQUARE1',
          src : require( '../../assets/images/eyeModels/eye2.png' )
        },
        {
          chose : false,
          name : 'SQUARE4',
          src : require( '../../assets/images/eyeModels/eye7.png' )
        },
        {
          chose : false,
          name : 'SQUARE5',
          src : require( '../../assets/images/eyeModels/eye8.png' )
        },
        {
          chose : false,
          name : 'CIRCLE1',
          src : require( '../../assets/images/eyeModels/eye1.png' )
        },
        {
          chose : false,
          name : 'CIRCLE2',
          src : require( '../../assets/images/eyeModels/eye3.png' )
        },
        {
          chose : false,
          name : 'CIRCLE3',
          src : require( '../../assets/images/eyeModels/eye4.png' )
        },
        {
          chose : false,
          name : 'CIRCLE4',
          src : require( '../../assets/images/eyeModels/eye9.png' )
        },
        {
          chose : false,
          name : 'CIRCLE5',
          src : require( '../../assets/images/eyeModels/eye10.png' )
        }
      ],
      demoBgImgs : [
        {
          chose : false,
          src : require( '../../assets/images/demoQrBg/1.png' ),
          url : 'resource:1.png'
        },
        {
          chose : false,
          src : require( '../../assets/images/demoQrBg/2.png' ),
          url : 'resource:2.png'
        },
        {
          chose : false,
          src : require( '../../assets/images/demoQrBg/3.png' ),
          url : 'resource:3.png'
        },
        {
          chose : false,
          src : require( '../../assets/images/demoQrBg/4.png' ),
          url : 'resource:4.png'
        },
        {
          chose : false,
          src : require( '../../assets/images/demoQrBg/5.png' ),
          url : 'resource:5.png'
        },
        {
          chose : false,
          src : require( '../../assets/images/demoQrBg/demo_bg6.jpg' ),
          url : 'resource:demo_bg6.jpg'
        },
        {
          chose : false,
          src : require( '../../assets/images/demoQrBg/demo_bg7.jpg' ),
          url : 'resource:demo_bg7.jpg'
        },
        {
          chose : false,
          src : require( '../../assets/images/demoQrBg/demo_bg8.jpg' ),
          url : 'resource:demo_bg8.jpg'
        },
        {
          chose : false,
          src : require( '../../assets/images/demoQrBg/demo_bg9.jpg' ),
          url : 'resource:demo_bg9.jpg'
        },
        {
          chose : false,
          src : require( '../../assets/images/demoQrBg/demo_bg10.jpg' ),
          url : 'resource:demo_bg10.jpg'
        }
      ],
      isEdit : false,
      qrData : this.qrText,
      qrBaseImg : this.baseImg,
      showBeautifyDialog : false,
      uploadUrl : process.env.BASE_URL + '/file/upload/image?pageFunc=门户二维码美化logo',
      navTabs : [
        {
          name : '样式库',
          index : '1'
        },
        {
          name : '上传LOGO',
          index : '2'
        },
        {
          name : '二维码',
          index : '3'
        },
        {
          name : '文本',
          index : '4'
        },
        {
          name : '背景',
          index : '5'
        }
      ],
      navId : '2',
      kong : require( '@/assets/images/kong.png' ),
      upLoadIcon : require( '@/assets/images/qrImg/1(8).png' ),
      noIcon : require( '@/assets/images/qrImg/1(7).png' ),
      upcsIcon1 : require( '@/assets/images/qrImg/1(4).png' ),
      showIcons : [
        {
          url : require( '@/assets/images/demoLogo/1.png' ),
          id : 1,
          file : 'resource:1.png'
        },
        {
          url : require( '@/assets/images/demoLogo/2.png' ),
          id : 2,
          file : 'resource:2.png'
        }, {
          url : require( '@/assets/images/demoLogo/3.png' ),
          id : 3,
          file : 'resource:3.png'
        }, {
          url : require( '@/assets/images/demoLogo/4.png' ),
          id : 4,
          file : 'resource:4.png'
        }, {
          url : require( '@/assets/images/demoLogo/5.png' ),
          id : 5,
          file : 'resource:5.png'
        }, {
          url : require( '@/assets/images/demoLogo/6.png' ),
          id : 6,
          file : 'resource:6.png'
        }, {
          url : require( '@/assets/images/demoLogo/7.png' ),
          id : 7,
          file : 'resource:7.png'
        }
      ],
      logoTypeIcons : [
        {
          url : require( '@/assets/images/qrImg/1(4).png' ),
          name : '原型',
          id : 'NORMAL'
        },
        {
          url : require( '@/assets/images/qrImg/1(5).png' ),
          name : '方形描边',
          id : 'ROUND'
        }, {
          url : require( '@/assets/images/qrImg/1(6).png' ),
          name : '圆形描边',
          id : 'CIRCLE'
        }
      ],
      iconsId : null,
      logoImg : '',
      upLogoImg : '',
      logoType : 'NORMAL',
      qrColor : 'rgb(0, 0, 0)',
      qrColors : ['rgb(0, 0, 0)', 'rgb(32, 46, 85)', 'rgb(49, 49, 138)', 'rgb(0, 113, 109)', 'rgb(138, 41, 48)',
        'rgb(112, 111, 98)', 'rgb(206, 217, 205)', 'rgb(74, 86, 116)'],
      qrFaultOption : [
        {
          label : '7%',
          value : 'L'
        },
        {
          label : '15%',
          value : 'M'
        },
        {
          label : '25%',
          value : 'Q'
        },
        {
          label : '30%',
          value : 'H'
        }
      ],
      qrFault : 'H',
      qrPoint : 'RECT',
      QrMaDianList : [],
      qrEyes : '',
      paddingOption : [
        {
          label : '小边距',
          value : 10
        },
        {
          label : '标准边距',
          value : 20
        },
        {
          label : '大边距',
          value : 30
        }
      ],
      qrPadding : 20,
      qrBackColor : 'rgb(255, 255, 255)',
      qrBackColors : ['rgba(255, 255, 255,1)', 'rgba(0, 0, 0,1)', 'rgba(32, 46, 85,1)', 'rgba(49, 49, 138,1)',
        'rgba(0, 113, 109,1)', 'rgba(138, 41, 48,1)', 'rgba(112, 111, 98,1)', 'rgba(206, 217, 205,1)',
        'rgba(74, 86, 116,1)'],
      qrInpText : '',
      qrTextFontSize : '16',
      fontSizeOption : [
        {
          label : '16px',
          value : '16'
        },
        {
          label : '18px',
          value : '18'
        },
        {
          label : '20px',
          value : '20'
        },
        {
          label : '22px',
          value : '22'
        },
        {
          label : '24px',
          value : '24'
        },
        {
          label : '26px',
          value : '26'
        },
        {
          label : '28px',
          value : '28'
        },
        {
          label : '30px',
          value : '30'
        }
      ],
      qrTextColor : 'rgb(0,0,0)',
      qrTextPosition : 'top',
      myQrCssList : [],
      pubQrList : [],
      diaTitle : '',
      downLoadOrSaveVisible : false,
      saveForm : {
        name : ''
      },
      sizes : [
        {
          label : '300x300px',
          value : 300
        },
        {
          label : '400x400px',
          value : 400
        }, {
          label : '500x500px',
          value : 500
        }, {
          label : '1000x1000px',
          value : 1000
        }
      ],
      downLoadSet : {
        size : 300,
        type : 'png'
      },
      upBgImg : '',
      upBgImgOp : 0.5,
      detectSpecial : false,
      bgOpacity : 1,
      eyeModel : '',
      qrParam : {}
    }
  },

  computed : {},

  created() {

  },
  mounted() {
    const _this = this
    this.downLoadSet = JSON.parse( localStorage.getItem( 'downLoadSet' ) ) ? JSON.parse( localStorage.getItem( 'downLoadSet' ) )
      : {
        size : 300,
        type : 'png'
      }
    eventBus.$on( 'resetQrCss', k => {
      _this.clearQrCss()
    } )
  },

  methods : {
    getDemoLogo( url ) {
      const img = url.replace( 'resource:', '' )
      return require( `@/assets/images/demoLogo/${img}` )
    },
    getDemoQrBg( url ) {
      const img = url.replace( 'resource:', '' )
      return require( `@/assets/images/demoQrBg/${img}` )
    },
    choseDemoBgImg( img ) {
      this.upBgImg = img.url
      this.bgOpacity = this.upBgImgOp
      this.qrBackColor = '(255,255,255)'
      this.getQrBase64()
    },
    choseEye( eyeModel ) {
      this.eyeModel = eyeModel.name
      for ( let i = 0; i < this.eyeModels.length; i++ ) {
        this.eyeModels[i].chose = false
      }
      eyeModel.chose = true
      this.getQrBase64()
    },
    beforeAvatarUploadBg( file ) {
      const types = ['image/jpg', 'image/jpeg', 'image/png', 'image/JPG', 'image/JPEG', 'image/PNG']
      const isImg = types.includes( file.type )

      const isLt2M = file.size / 1024 / 1024 < 1
      if ( !isLt2M ) {
        this.$message.error( '上传图片大小不能超过 1M!' )
        return false
      }
      if ( !isImg ) {
        this.$message.error( '上传图片格式错误，仅限jpg，jpeg，png格式' )
        return false
      }
      return isLt2M && isImg
    },
    handleAvatarSuccessBg( res, file ) {
      this.upBgImg = res.data
      this.bgOpacity = this.upBgImgOp
      this.qrBackColor = '(255,255,255)'
      this.getQrBase64()
    },
    removeUpBgImg() {
      this.upBgImg = ''
      this.qrChange()
    },
    upBgImgOpChange( val ) {
      this.bgOpacity = val
      this.getQrBase64()
    },
    openLogin() {
      eventBus.$emit( 'loginOrRegister', 'openLogin' )
    },
    openDownLoad() {
      this.diaTitle = '下载设置'
      this.qrData = this.qrText
      this.downLoadOrSaveVisible = true
      document.documentElement.style.overflowY = 'hidden'
    },
    openDialog( idisCode ) {
      this.showBeautifyDialog = true
      this.qrData = this.qrText
      this.qrBaseImg = this.baseImg
      if ( idisCode ) {
        this.idisCode = idisCode
      } else {
        this.idisCode = ''
      }
      this.getQrStyleList()
      document.documentElement.style.overflowY = 'hidden'
    },
    handleQrClose() {
      // this.clearQrCss()
      // if (this.isEdit) {
      //   this.$confirm('你修改的内容未保存，是否保存？', '提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      //   }).then(() => {
      //     this.sureQr()
      //   }).catch(() => {
      //     this.resetQrStyle()
      //     this.qrVisible = false
      //   })
      // } else {
      //   this.getQrBase64()
      //   this.qrVisible = false
      // }
      this.navId = 2
      this.showBeautifyDialog = false
      document.documentElement.style.overflowY = 'auto'
    },
    getQrStyleList() {
      getQrStyleList().then( res => {
        this.QrMaDianList = res.rows
      } )
    },
    qrPubCssTempList() {
      qrPubCssTempList().then( res => {
        this.pubQrList = res.rows
      } )
    },
    qrMyCssList() {
      qrMyCssList().then( res => {
        this.myQrCssList = res.rows
      } )
    },
    selectTab( item ) {
      if ( item.index === '1' ) {
        if ( this.$store.getters.token && this.$store.getters.token != undefined ) {
          this.qrMyCssList()
        }
        this.qrPubCssTempList()
      }
      this.navId = item.index
    },
    beforeAvatarUploadLogo( file ) {
      const types = ['image/jpg', 'image/jpeg', 'image/png', 'image/JPG', 'image/JPEG', 'image/PNG']
      const isImg = types.includes( file.type )

      const isLt2M = file.size / 1024 / 1024 < 1
      if ( !isLt2M ) {
        this.$message( {
          type : 'warning',
          message : '上传Logo大小不能超过 1M!'
        } )
        return false
      }
      if ( !isImg ) {
        this.$message( {
          type : 'warning',
          message : '上传Logo格式错误，仅限jpg，jpeg，png格式'
        } )
        return false
      }
      return isLt2M && isImg
    },
    handleAvatarSuccessLogo( res ) {
      this.upLogoImg = res.data
      this.getQrBase64()
    },
    selectIcons( item ) {
      if ( this.iconsId != item.id ) {
        this.iconsId = item.id
        this.logoImg = item.file
        this.upLogoImg = ''
        this.getQrBase64()
      }
    },
    selectTypeIcons( item ) {
      if ( this.logoType != item.id ) {
        this.logoType = item.id
        this.getQrBase64()
      }
    },
    noLogoImg() {
      this.logoImg = ''
      this.upLogoImg = ''
      this.iconsId = null
      this.getQrBase64()
    },
    qrChange() {
      this.qrTextColor = this.qrTextColor ? this.qrTextColor : 'rgb(0,0,0)'
      this.qrBackColor = this.qrBackColor ? this.qrBackColor : 'rgb(255,255,255)'
      this.qrColor = this.qrColor ? this.qrColor : 'rgb(0,0,0)'
      this.getQrBase64()
    },
    qrChange2() {
      this.getQrBase64()
      this.$message( {
        type : 'warning',
        duration : '5000',
        message : '当前样式可能无法扫描，请扫码确认后再保存'
      } )
    },
    choiceQrColor( v ) {
      this.qrColor = v
      this.getQrBase64()
    },
    choiceQrBackColor( v ) {
      this.qrBackColor = v
      this.$nextTick( () => {
        const bgColor = this.qrBackColor !== null ? this.qrBackColor.substring( this.qrBackColor.indexOf( '(' ) + 1,
          this.qrBackColor.lastIndexOf( ')' ) ).split( /,\s*/ ) : [255, 255, 255]
        this.bgOpacity = `${bgColor[3] ? bgColor[3] : 1}`
        this.upBgImg = ''
        this.getQrBase64()
      } )
    },
    bgColorQrChange() {
      const bgColor = this.qrBackColor !== null ? this.qrBackColor.substring( this.qrBackColor.indexOf( '(' ) + 1,
        this.qrBackColor.lastIndexOf( ')' ) ).split( /,\s*/ ) : [255, 255, 255]
      this.bgOpacity = `${bgColor[3] ? bgColor[3] : 1}`
      this.upBgImg = ''
      this.$nextTick( () => {
        this.getQrBase64()
      } )
    },
    choiceQrTemp( item ) {
      this.qrColor = `rgb(${item.drawPreColor})`
      this.qrBackColor = `rgb(${item.bgColor})`
      this.qrPoint = item.drawPreStyle
      this.upLogoImg = item.logo
      this.logoType = item.logoStyle
      this.qrPadding = item.padding
      this.qrInpText = item.textContent
      this.qrTextPosition = item.textPos
      this.qrTextFontSize = item.textSize
      this.qrTextColor = `rgb(${item.textColor})`
      this.qrFault = item.errorLevel
      this.bgOpacity = item.bgOpacity
      this.upBgImg = item.bgImg
      this.detectSpecial = item.detectSpecial
      this.eyeModel = item.eyeModel
      this.getQrBase64()
    },
    delQrTemp( item ) {
      delMyQrCss( item.id ).then( res => {
        if ( res.code == 200 ) {
          this.$message( {
            type : 'success',
            message : '删除成功'
          } )
          this.qrMyCssList()
        }
      } )
    },
    clearQrCss() {
      this.logoImg = ''
      this.upLogoImg = ''
      this.qrColor = 'rgb(0, 0, 0)'
      this.qrTextColor = 'rgb(0, 0, 0)'
      this.qrFault = 'H'
      this.qrPoint = 'RECT'
      this.qrEyes = ''
      this.qrPadding = 20
      this.logoType = 'NORMAL'
      this.qrInpText = ''
      this.iconsId = null
      this.qrBackColor = 'rgb(255, 255, 255)'
      this.qrTextPosition = 'top'
      this.qrTextFontSize = '16'
      this.navId = 2
      this.upBgImg = ''
      this.bgOpacity = 1
      this.detectSpecial = false
      this.eyeModel = 'EYE_DEFAULT'
      if ( this.qrData != '' ) {
        this.getQrBase64()
      }
    },
    sureQr() {
      this.qrColor = this.qrColor ? this.qrColor : 'rgb(0, 0, 0)'
      this.qrBackColor = this.qrBackColor ? this.qrBackColor : 'rgb(255,255,255)'
      this.qrTextColor = this.qrTextColor ? this.qrTextColor : 'rgb(0, 0, 0)'
      this.showBeautifyDialog = false
      this.isEdit = false
      this.navId = 2
      this.$emit( 'sureQr', this.qrBaseImg )
      this.$emit( 'qrParam', this.qrParam )
      document.documentElement.style.overflowY = 'auto'
    },
    saveQr() {
      this.diaTitle = '保存为我的样式，下次可复用'
      this.downLoadOrSaveVisible = true
    },
    onSubmitDownLoad() {
      localStorage.setItem( 'downLoadSet', JSON.stringify( this.downLoadSet ) )
      this.getQrBase64()
      this.downLoadOrSaveVisible = false
    },
    onSubmitSave() {
      if ( this.saveForm.name === '' ) {
        this.$message( {
          type : 'warning',
          message : '请填写模板名称！'
        } )
        return false
      } else {
        const bgColor = this.qrBackColor !== null ? this.qrBackColor.substring( this.qrBackColor.indexOf( '(' ) + 1,
          this.qrBackColor.lastIndexOf( ')' ) ).split( /,\s*/ ) : [255, 255, 255]
        const textColor = this.qrTextColor !== null ? this.qrTextColor.substring( this.qrTextColor.indexOf( '(' ) + 1,
          this.qrTextColor.lastIndexOf( ')' ) ).split( /,\s*/ ).toString() : '0,0,0'
        const drawPreColor = this.qrColor !== null ? this.qrColor.substring( this.qrColor.indexOf( '(' ) + 1,
          this.qrColor.lastIndexOf( ')' ) ).split( /,\s*/ ).toString() : '0,0,0'
        let logo = ''
        if ( this.upLogoImg == '' && this.logoImg == '' ) {
          logo == ''
        } else {
          if ( this.upLogoImg != '' ) {
            logo = this.upLogoImg
          } else if ( this.logoImg != '' ) {
            logo = this.logoImg
          }
        }
        const p = {
          'name' : this.saveForm.name,
          'detectSpecial' : this.detectSpecial, // 是否码点形状作用于探测点
          'detectOutColor' : '', // 非自定义探测点图片情况下,探测点的外边框颜色
          'detectInColor' : '', // 非自定义探测点图片情况下,探测点的内边框颜色
          'drawPreColor' : drawPreColor, // 码点颜色
          'drawPreStyle' : this.qrPoint, // 码点形状
          'logo' : logo, // logo地址
          'logoStyle' : this.logoType,
          'padding' : this.qrPadding, // 边距
          'detectImg' : '', // 探测点图片地址
          'textAdd' : this.qrInpText != '', // 是否追加文字
          'textContent' : this.qrInpText,
          'textPos' : this.qrTextPosition, // 文字位置
          'textSize' : this.qrTextFontSize, // 文字大小
          'textColor' : textColor, // 文字颜色
          'bgColor' : `${bgColor[0]},${bgColor[1]},${bgColor[2]}`, // 二维码背景
          'bgImg' : this.upBgImg, // 背景图
          'bgOpacity' : `${bgColor[3] ? bgColor[3] : 1}`, // 背景透明度
          'eyeModel' : this.eyeModel, // 眼睛模式
          'errorLevel' : this.qrFault, // 容错级别
          'width' : this.downLoadSet.size, // 宽度
          'height' : this.downLoadSet.size, // 高度
          'msg' : ''// 码内容
        }
        saveMyQrCss( p ).then( res => {
          if ( res.code == 200 ) {
            this.downloadClose()
            this.$message( {
              type : 'success',
              message : '保存成功'
            } )
            this.qrMyCssList()
          }
        } )
      }
    },
    downloadClose() {
      this.saveForm.name = ''
      this.downLoadOrSaveVisible = false
      // this.getQrBase64()
      // this.downLoadOrSaveVisible = false
      // setTimeout(()=>{
      //   _this.$emit('sureQr', this.qrBaseImg);
      // },100)
    },
    getQrBase64() {
      const bgColor = this.qrBackColor !== null ? this.qrBackColor.substring( this.qrBackColor.indexOf( '(' ) + 1,
        this.qrBackColor.lastIndexOf( ')' ) ).split( /,\s*/ ) : [255, 255, 255]
      const textColor = this.qrTextColor !== null ? this.qrTextColor.substring( this.qrTextColor.indexOf( '(' ) + 1,
        this.qrTextColor.lastIndexOf( ')' ) ).split( /,\s*/ ).toString() : '0,0,0'
      const drawPreColor = this.qrColor !== null ? this.qrColor.substring( this.qrColor.indexOf( '(' ) + 1,
        this.qrColor.lastIndexOf( ')' ) ).split( /,\s*/ ).toString() : '0,0,0'
      let logo = ''
      if ( this.upLogoImg == '' && this.logoImg == '' ) {
        logo == ''
      } else {
        if ( this.upLogoImg != '' ) {
          logo = this.upLogoImg
        } else if ( this.logoImg != '' ) {
          logo = this.logoImg
        }
      }

      const p = {
        'eyeModel' : this.eyeModel,
        'detectSpecial' : this.detectSpecial, // 是否码点形状作用于探测点
        'detectOutColor' : '', // 非自定义探测点图片情况下,探测点的外边框颜色
        'detectInColor' : '', // 非自定义探测点图片情况下,探测点的内边框颜色
        'drawPreColor' : drawPreColor, // 码点颜色
        'drawPreStyle' : this.qrPoint, // 码点形状
        'logo' : logo, // logo地址
        'logoStyle' : this.logoType,
        'padding' : this.qrPadding, // 边距
        'detectImg' : '', // 探测点图片地址
        'textAdd' : this.qrInpText != '', // 是否追加文字
        'textContent' : this.qrInpText,
        'textPos' : this.qrTextPosition, // 文字位置
        'textSize' : this.qrTextFontSize, // 文字大小
        'textColor' : textColor, // 文字颜色
        'bgColor' : `${bgColor[0]},${bgColor[1]},${bgColor[2]}`, // 二维码背景
        'bgImg' : this.upBgImg, // 背景图
        'bgOpacity' : this.bgOpacity, // 背景透明度
        'errorLevel' : this.qrFault, // 容错级别
        'width' : this.downLoadSet.size, // 宽度
        'height' : this.downLoadSet.size, // 高度
        'msg' : this.qrData, // 码内容
        'idisContent' : this.idisCode
      }
      getQrBase64( p ).then( res => {
        this.qrBaseImg = `data:image/png;base64,${res.data}`
        this.qrParam = p
        if ( !this.isEdit ) {
          this.isEdit = true
        }
        // setTimeout(() => {
        //   this.$emit('sureQr', this.qrBaseImg);
        // }, 100)
      } )
    }
  }
}

