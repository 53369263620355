
export default {
  data() {
    return {
      uploadUrl : process.env.BASE_URL + '/file/upload/file?pageFunc=门户扫码模板(音乐)',
      item : {
        value : {}
      },
      fileList : []
    }
  },
  methods : {
    asyncMusicInfo( item ) {
      this.item = item
      this.fileList = []
      if ( item.value.music != '' ) {
        this.fileList.push( { url : item.value.music, name : '' } )
      }
    },
    handleAvatarSuccess( res ) {
      this.item.value.music = res.data
    },
    handleChange( file, fileList ) {
      if ( fileList.length > 0 ) {
        this.fileList = [fileList[fileList.length - 1]] // 展示最后一次选择的文件
      }
    },
    handleRemove() {
      this.item.value.music = ''
    },
    beforeAvatarUpload( file ) {
      const isLt2M = file.size / 1024 / 1024 < 2

      if ( !isLt2M ) {
        this.$message.error( '上传音频大小不能超过 2MB!' )
      }
      return isLt2M
    }
  }
}
