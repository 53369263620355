
import TemplateStyleboxItem from './TemplateStyleboxItem'

export default {
  components : {
    TemplateStyleboxItem
  },
  props : {
    item : Object,
    code : Object
  }
}
