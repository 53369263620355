
export default {
  props : {
    item : Object,
    images : Array
  },
  computed : {
    imageLength() {
      return this.images.length
    }
  },
  methods : {
    imageSrc( src ) {
      if ( src.startsWith( 'http' ) ) {
        return src + '?x-image-process=style/width-1024'
      }
      return src
    }
  }
}
