
import Badge from '@/assets/images/code-information-badge.png'
import Badge3 from '@/assets/images/code-information-badge-3.png'
import Beian1 from '@/assets/images/beian-1.png'
import Beian2 from '@/assets/images/beian-2.png'

export default {
  props : {
    code : Object
  },
  data() {
    return {
      Badge,
      Badge3,
      Beian1,
      Beian2,
      find : false
    }
  },
  watch : {
    'code.idisCode' : {
      handler : async function() {
        const idisCode = this.code.idisCode
        if ( idisCode ) {
          const codeResult = await fetch( `https://dms.citln.cn/r/${idisCode}`, {
            credentials : 'same-origin'
          } ).then( d => d.json() )
          this.find = codeResult.responseCode === 1
        }
      },
      immediate : true
    }
  }
}
