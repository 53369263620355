
export default {
  props : {
    item : Object,
    code : Object
  },
  computed : {
    price() {
      if ( !this.code.price ) {
        return '(未配置价格)'
      }
      let price = '¥' + this.code.price
      const dotIndex = price.indexOf( '.' )
      if ( dotIndex == -1 ) {
        price = price + '.00'
      } else if ( dotIndex === price.length - 2 ) {
        price = price + '0'
      }
      return price
    }
  }
}
