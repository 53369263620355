
import { format } from '@/utils/date-utils'
import SpecialTitle from './SpecialTitle'

export default {
  components : {
    SpecialTitle
  },
  props : {
    item : Object,
    code : Object,
    batch : Object
  },
  computed : {
    shelfLife() {
      const oneDay = 24 * 60 * 60 * 1000
      const startDate = new Date( this.batch.productionDate.substring( 0, 10 ) ) / oneDay
      const endDate = new Date( this.batch.beyondShelfLifeDate.substring( 0, 10 ) ) / oneDay
      const today = new Date( format( new Date(), 'yyyy-MM-dd' ) ) / oneDay
      const res = {}
      res.total = endDate - startDate + 1
      res.beyondShelfLife = today > endDate
      if ( res.beyondShelfLife ) {
        res.overTime = today - endDate
        res.percentage = 100
      } else {
        res.remains = endDate - today + 1
        if ( today - startDate > 0 ) {
          res.percentage = Math.floor( ( ( today - startDate ) / res.total ) * 100 )
        } else {
          res.percentage = 0
        }
      }
      return res
    },
    expireDate() {
      const date = new Date( this.batch.beyondShelfLifeDate.substring( 0, 10 ) )
      return format( date, 'yyyy/MM/dd' )
    }
  }
}
