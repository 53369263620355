
import translaterIcon from '@/assets/images/translater.png'

export default {
  props : {
    item : Object
  },
  data() {
    return {
      translaterIcon
    }
  },
  mounted() {
    this.initLocale()
  },
  methods : {
    initLocale() {
      var locale = sessionStorage.getItem( 'locale' )
      if ( locale == null || locale == '' ) {
        sessionStorage.setItem( 'locale', 'cn' )
      }
      this.$i18n.locale = sessionStorage.getItem( 'locale' )
    },
    asyncTitleInfo( item ) {
      console.log( item )
    },
    // 中英切换
    changeLocale() {
      var locale = this.$i18n.locale
      var newLocale = 'cn'
      if ( locale == 'cn' ) {
        newLocale = 'en'
      } else {
        newLocale = 'cn'
      }
      sessionStorage.setItem( 'locale', newLocale )
      location.reload()
    }
  }
}
