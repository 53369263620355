
export default {
  data() {
    return {
      uploadUrl : process.env.BASE_URL + '/file/upload/image?pageFunc=门户扫码模板图片',
      // 控件用
      fileList : [],
      // 只记录图片地址
      images : [],
      img : require( '../../assets/images/img_qr.png' ),
      item : {
        value : {}
      }
    }
  },
  methods : {
    asyncImageInfo( item ) {
      this.item = item
      this.fileList = []
      this.images = []

      item.value.images.forEach( element => {
        this.fileList.push( { url : element, name : '' } )
        this.images.push( element )
      } )
    },
    handleRemove( file, fileList ) {
      if ( file.response ) {
        const idx = this.item.value.images.findIndex( url => url === file.response.data )
        if ( idx > -1 ) {
          this.item.value.images.splice( idx, 1 )
        }
        const idxx = this.images.findIndex( item => item.url === file.response.data )
        if ( idxx > -1 ) {
          this.images.splice( idx, 1 )
        }
      } else {
        const idx = this.item.value.images.findIndex( url => url === file.url )
        if ( idx > -1 ) {
          this.item.value.images.splice( idx, 1 )
        }
        const idxx = this.images.findIndex( item => item.url === file.url )
        if ( idxx > -1 ) {
          this.images.splice( idx, 1 )
        }
      }
      this.fileList = fileList
    },
    handlePreview( file ) {
      console.log( file )
    },
    handleSuccess( res ) {
      if ( res && res.code === 200 ) {
        this.images.push( res.data )
        this.item.value.images.push( res.data )
      } else {
        this.$message.error( '图片上传失败，请重试！' )
      }
    },
    beforeUpload( file ) {
      const types = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/JPG', 'image/JPEG', 'image/PNG', 'image/GIF']
      const isImg = types.includes( file.type )
      const isLt2M = file.size / 1024 / 1024 < 2

      if ( !isLt2M ) {
        this.$message.error( '上传图片大小不能超过 2MB!' )
        return false
      }
      if ( !isImg ) {
        this.$message.error( '上传图片格式错误，仅限jpg，jpeg，png，gif格式' )
        return false
      }
      return isLt2M && isImg
    },
    handleExceed() {
      this.$message( {
        type : 'warning',
        message : '最多上传10张图片'
      } )
    }
  }
}
