
export default {
  props : {
    config : Object
  },
  methods : {
    imageSrc( src ) {
      return src + '?x-image-process=style/width-1024'
    }
  }
}
