
import eventBus from '@/utils/event-bus'

export default {
  data() {
    return {
      limit : 30,
      uploadUrl : process.env.BASE_URL + '/file/upload/image?pageFunc=门户扫码模板三视图',
      // 控件用
      fileList : [],
      item : {
        value : {
          images : []
        }
      },
      disableUpload : false,
      dragStartIndex : -1,
      dragToIndex : -1
    }
  },
  methods : {
    syncInfo( item ) {
      this.item = item
      this.fileList = []
      for ( const url of item.value.images ) {
        this.fileList.push( {
          url : url,
          name : '图像' + ( this.fileList.length + 1 )
        } )
      }
    },
    handleSuccess( res, file, fileList ) {
      // console.log('upload', res, file, fileList)
      if ( res && res.code === 200 ) {
        this.item.value.images.push( res.data )
        this.fileList = fileList
      } else {
        this.$message.error( '图片上传失败，请重试！' )
      }
    },
    handleExceed() {
      eventBus.$emit( 'showMessage', {
        message : `最多上传${this.limit}张图片`
      } )
    },
    swap( a, b ) {
      // console.log('swap', a, b)
      const urlA = this.item.value.images[a]
      const urlB = this.item.value.images[b]
      const fileA = this.fileList[a]
      const fileB = this.fileList[b]
      this.$set( this.item.value.images, a, urlB )
      this.$set( this.item.value.images, b, urlA )
      this.$set( this.fileList, a, fileB )
      this.$set( this.fileList, b, fileA )
    },
    remove( idx ) {
      // console.log('remove', idx)
      this.item.value.images.splice( idx, 1 )
      this.fileList.splice( idx, 1 )
    },
    dragStartEvent( idx ) {
      // console.log('drag start', idx)
      this.dragStartIndex = idx
    },
    dragEndEvent( e ) {
      // console.log('drag end', e)
      let item = e.target
      if ( !item && e.path ) {
        item = e.path.filter( e => e.className === 'upload-item' )[0]
      }
      if ( item ) {
        const fromIdx = Number.parseInt( item.getAttribute( 'data-idx' ) )
        if ( this.dragStartIndex == fromIdx && this.dragToIndex != fromIdx ) {
          const img = this.item.value.images[fromIdx]
          this.item.value.images.splice( fromIdx, 1 )
          this.item.value.images.splice( this.dragToIndex, 0, img )
          const file = this.fileList[fromIdx]
          this.fileList.splice( fromIdx, 1 )
          this.fileList.splice( this.dragToIndex, 0, file )
        }
      }
      this.dragStartIndex = -1
      this.dragToIndex = -1
      this.clearAllBorder()
    },
    dragEnterEvent( e ) {
      let item = e.target
      if ( !item && e.path ) {
        item = e.path.filter( e => e.className === 'upload-item' )[0]
      }
      if ( item ) {
        item.style.border = '1px solid red'
        this.dragToIndex = Number.parseInt( item.getAttribute( 'data-idx' ) )
      }
      e.preventDefault()
    },
    dragLeaveEvent( e ) {
      this.dragToIndex = -1
      this.clearAllBorder()
      e.preventDefault()
    },
    clearAllBorder() {
      const uploadItemNodes = this.$el.querySelectorAll( '.upload-item' )
      for ( const node of uploadItemNodes ) {
        node.style.border = '1px solid #eeeeee'
      }
    }
  }
}
