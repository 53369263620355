
// import { getJsonResult } from '@/utils/server-wrapper'
import { getSecurityTemplate } from '@/api/product'
export default {
  props : {
    item : Object,
    idisCode : String,
    productName : String,
    tenant : Object
  },
  data() {
    return {
      stage : 'query',
      successText : '',
      failureText : '',
      voiceText : ''
    }
  },
  watch : {
    'item.value.configArea' : function( configArea ) {
      if ( configArea ) {
        this.stage = configArea
      }
    },
    'item.value.successTemplate' : {
      handler : async function( id ) {
        getSecurityTemplate( id, '1' ).then( ( response ) => {
          const template = response.data
          this.successText = this.replaceText( template.text )
        } )

        // let url = `/wx/securityTemplate?id=${id}&scenario=success`
        // const template = await getJsonResult(url)
        // this.successText = this.replaceText(template.text)
      },
      immediate : true
    },
    'item.value.failureTemplate' : {
      handler : async function( id ) {
        getSecurityTemplate( id, '0' ).then( ( response ) => {
          const template = response.data
          this.successText = this.replaceText( template.text )
        } )

        // let url = `/wx/securityTemplate?id=${id}&scenario=failure`
        // const template = await getJsonResult(url)
        // this.failureText = this.replaceText(template.text)
      },
      immediate : true
    }
  },
  methods : {
    replaceText( text ) {
      if ( text ) {
        text = text.replace( /\{count\}/g, 1 )
        text = text.replace( /\{product\}/g, this.productName )
        text = text.replace( /\{firstQueryTime\}/g, '2020-01-01' )
        text = text.replace( /\{lastQueryTime\}/g, '2020-01-01' )
        text = text.replace( /\{company\}/g, this.tenant.name )
        text = text.replace( /\{code\}/g, '<测试idis 码>' )
      }
      return text
    }
  }
}
