
import SpecialTitle from './SpecialTitle'

export default {
  components : {
    SpecialTitle
  },
  props : {
    item : Object,
    code : Object,
    codeAttrs : Array,
    batch : Object
  },
  data : function() {
    return {
      locale : 'en',
      sourceUrl : `/wx/trace-source?codeId=` + this.code.id + '&locale=' + this.locale,
      enAttrNames : ['产品名称', '产品分类']
    }
  },
  mounted() {
    this.initLocale()
  },
  methods : {
    initLocale() {
      var locale = sessionStorage.getItem( 'locale' )
      if ( locale == null || locale == '' ) {
        sessionStorage.setItem( 'locale', 'cn' )
      }
      this.$i18n.locale = sessionStorage.getItem( 'locale' )
      this.sourceUrl = `/wx/trace-source?codeId=` + this.code.id + '&locale=' + this.$i18n.locale
    },
    changeLang( num ) {
      if ( num == 2 ) {
        this.$i18n.locale = 'en'
      } else {
        this.$i18n.locale = 'cn'
      }
    }
  }
}
