
import SpecialTitle from './SpecialTitle'
export default {
  components : {
    SpecialTitle
  },
  props : {
    product : Object
  }
}
