
export default {
  props : {
    code : Object,
    active : String
  },
  methods : {
    getUrl( active ) {
      if ( !this.code.idisCode || this.active === active ) {
        return '#'
      }
      if ( active === 'home' ) {
        return `/wx?code=${encodeURIComponent( this.code.idisCode )}`
      } else if ( active === 'warranty' ) {
        // return `/wx/warranty?idisCode=${encodeURIComponent(this.code.idisCode)}`
        return `/frontend/w/warranty?idisCode=${encodeURIComponent( this.code.idisCode )}`
      } else if ( active === 'suretyService' ) {
        return `/wx/surety-service?productId=${this.code.productId}&tenantId=${this.code.tenantId}`
      } else {
        return '#'
      }
    }
  }
}
