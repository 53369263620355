
export default {
  data() {
    return {
      uploadUrl : process.env.BASE_URL + '/file/upload/image?pageFunc=门户扫码模板标题图',
      item : {
        value : {}
      },
      fileList : [],
      img : require( '../../assets/images/beian-1.png' )
    }
  },
  methods : {
    asyncTitleInfo( item ) {
      this.item = item
      this.fileList = []
      if ( item.value.icon != '' ) {
        this.fileList.push( { url : item.value.icon, name : '' } )
      }
    },
    handleAvatarSuccess( res ) {
      this.item.value.icon = res.data
    },
    beforeAvatarUpload( file ) {
      const types = ['image/jpg', 'image/jpeg', 'image/png', 'image/JPG', 'image/JPEG', 'image/PNG']
      const isImg = types.includes( file.type )
      const isLt2M = file.size / 1024 / 1024 < 2

      if ( !isLt2M ) {
        this.$message.error( '上传头像图片大小不能超过 2MB!' )
      }
      if ( !isImg ) {
        this.$message.error( '上传图片格式错误，仅限jpg，jpeg，png格式' )
      }
      return isLt2M && isImg
    },
    handleRemove() {
      this.item.value.icon = ''
    },
    onExceed() {
      this.$message.warning( '只能上传一张图片!' )
    },
    changeColor( v ) {
      if ( !v ) {
        this.item.value.bgcolor = '#004da1'
      }
    }
  }
}
