
export default {
  props : {
    item : Object
  },
  computed : {
    imageLength() {
      return this.item.value.images.length
    },
    height() {
      return this.item.value.height || '200px'
    }
  },
  methods : {
    imageSrc( src ) {
      return src + '?x-image-process=style/width-1024'
    }
  }
}
