
import CONFIG from '@/othComponents/codeTemplate/config'

export default {
  components : {},
  props : ['sortList'],
  data() {
    return {
      CONFIG,
      shineId : -1
    }
  },
  methods : {
    move( type, i ) {
      if ( type === 'down' ) {
        this.swapArr( this.sortList, i, i + 1 )
      } else {
        this.swapArr( this.sortList, i, i - 1 )
      }
    },
    swapArr( arr, index1, index2 ) {
      arr[index1] = arr.splice( index2, 1, arr[index1] )[0]
    },
    // 父组件调用这个方法来高亮要选中的项目
    hightItem( item ) {
      this.shineId = item.id
      const el = this.$refs[`item-${item.id}`][0]
      this.$el.scrollTop = el.offsetTop
    },
    // 选择项目的回调
    selectItem( item, i ) {
      this.$emit( 'selectItem', { item, i } )
      this.shineId = item.id
    },
    remove( item ) {
      this.$confirm( '确定要删除该组件吗？', '提示', {
        confirmButtonText : '确定',
        cancelButtonText : '取消',
        closeOnClickModal : false,
        type : 'warning'
      } ).then( async() => {
        this.$emit( 'removeItem', item )
      } )
    }
  }
}
