
import '@/assets/styles/weixin.scss'

export default {
  props : {
    item : Object
  },
  methods : {
    imageSrc( src ) {
      return src + '?x-image-process=style/width-1024'
    }
  }
}
