
import SpecialTitle from './SpecialTitle'

export default {
  components : {
    SpecialTitle
  },
  props : {
    item : Object,
    code : Object,
    codeAttrs : Array,
    batch : Object
  },
  data : function() {
    return {
      locale : 'en',
      sourceUrl : `/wx/trace-source?codeId=` + this.code.id + '&locale=' + this.locale,
      enAttrNames : ['产品名称', '产品分类']
    }
  },
  computed : {
    infoList() {
      let res = []
      res.push( { name : '产品名称', value : this.code.productName, lang : 'productName' } )
      if ( this.batch.displayOption !== 'hide' ) {
        res.push( { name : '产品批次', value : this.code.batchNo, lang : 'batchNo' } )
      }
      res = res.concat( [
        { name : '产品分类', value : this.code.categoryName, lang : 'categoryName' }
      ] )
      res = res.filter( i => i.value && ( '' + i.value ).trim() )
      if ( this.codeAttrs ) {
        for ( const i of this.codeAttrs ) {
          if ( ( !i.references || i.references.length == 0 ) && !( i.attrCategoryId || i.attrCategoryName ) ) {
            if ( i.attrValue && i.attrValue.startsWith( 'JSON--{' ) ) {
              try {
                const json = JSON.parse( i.attrValue.substring( 6 ) )
                for ( const key of Object.keys( json ) ) {
                  res.push( {
                    name : key,
                    value : json[key]
                  } )
                }
                continue
              } catch {
                // do nothing
              }
            }
            res.push( {
              name : i.attrNameCn,
              value : i.attrValue
            } )
          }
        }
      }
      return res
    }
  },
  mounted() {
    this.initLocale()
  },
  methods : {
    initLocale() {
      var locale = sessionStorage.getItem( 'locale' )
      if ( locale == null || locale == '' ) {
        sessionStorage.setItem( 'locale', 'cn' )
      }
      this.$i18n.locale = sessionStorage.getItem( 'locale' )
      this.sourceUrl = `/wx/trace-source?codeId=` + this.code.id + '&locale=' + this.$i18n.locale
    },
    changeLang( num ) {
      if ( num == 2 ) {
        this.$i18n.locale = 'en'
      } else {
        this.$i18n.locale = 'cn'
      }
    }
  }
}
