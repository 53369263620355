
export default {
  props : ['sortList'],
  data() {
    return {
      fileList : [],
      textForm : {
        text : '',
        radio : '',
        val : '1'
      },
      item : {},
      rules : {
        text : [
          { required : true, message : '请填写内容', trigger : 'blur' },
          { min : 1, max : 500, message : '文本长度在1 到500个字之间', trigger : 'blur' }
        ]
      }
    }
  },
  methods : {
    asyncTextInfo( item ) {
      this.textForm.text = item.value.text
      if ( item.value.style === 'white-space: pre-wrap;line-height:1.5;text-align:left' ) {
        this.textForm.radio = '1'
      } else if ( item.value.style === 'white-space: pre-wrap;line-height:1.5;text-align:center' ) {
        this.textForm.radio = '2'
      } else if ( item.value.style === 'white-space: pre-wrap;line-height:1.5;text-align:right' ) {
        this.textForm.radio = '3'
      }
      this.item = item
    },
    setTextValue( val ) {
      this.item.value.text = this.textForm.text
      if ( val === '1' ) {
        this.item.value.style = 'white-space: pre-wrap;line-height:1.5;text-align:left'
      } else if ( val === '2' ) {
        this.item.value.style = 'white-space: pre-wrap;line-height:1.5;text-align:center'
      } else if ( val === '3' ) {
        this.item.value.style = 'white-space: pre-wrap;line-height:1.5;text-align:right'
      }
    },
    rChange : function( val ) {
      this.setTextValue( val )
    }
  }
}
