
import insImage from '@/assets/images/template-video-pic.png'

export default {
  data() {
    return {
      errorMsg : '',
      helpVisible : false,
      fileList : [],
      item : {
        value : {
          iframeCode : ''
        }
      },
      insImage
    }
  },
  mounted() {
    // 如果组件变更了则
    // this.$refs.helpPanel.style.height = window.innerHeight - 131 + 'px'
  },
  methods : {
    asyncVideoInfo( item ) {
      this.item = item
    },
    setInputValue() {
      const url = this.item.value.iframeCode
      const match = ( url || '' ).match( /src="([^"]+)"/ )
      if ( !match || !match[0] ) {
        this.errorMsg = '没有检测到视频地址'
        return
      } else {
        this.errorMsg = ''
      }
      this.item.value.src = match[1]
    },
    triggerHelpPanel() {
      this.helpVisible = !this.helpVisible
    }
  }
}
