
const defaultIcon = require( '@/assets/images/logo.png' )
import RelatedProduct from '@/othComponents/codeTemplate/RelatedProduct'
import ProductDesc from '@/othComponents/codeTemplate/ProductDesc'
import CompanyInfo from '@/othComponents/codeTemplate/CompanyInfo'
import ProductInfo from '@/othComponents/codeTemplate/ProductInfo'
import ValidateSearchEdit from '@/othComponents/configuration/ValidateSearchEdit'
import TemplatePictures from '@/othComponents/codeTemplate/TemplatePictures'
import TemplateVideo from '@/othComponents/codeTemplate/TemplateVideo'
import TemplateStylebox from '@/othComponents/codeTemplate/TemplateStylebox'
import TemplateText from '@/othComponents/codeTemplate/TemplateText'
import TemplateHead from '@/othComponents/codeTemplate/TemplateHead'
import TemplateCarousel from '@/othComponents/codeTemplate/TemplateCarousel'
import CodeInformation from '@/othComponents/codeTemplate/CodeInformation'
import ProductImages from '@/othComponents/codeTemplate/ProductImages'
import ProductBarcode from '@/othComponents/codeTemplate/ProductBarcode'
import ProductPrice from '@/othComponents/codeTemplate/ProductPrice'
import ProductQualityGuaranteePeriod from '@/othComponents/codeTemplate/ProductQualityGuaranteePeriod'
import CONFIG from '@/othComponents/codeTemplate/config'
import BaseMusic from '@/othComponents/codeTemplate/BaseMusic'
import BottomNavigator from '@/othComponents/codeTemplate/BottomNavigator'
import ThreeImg from '@/othComponents/codeTemplate/ThreeImg'
import BaseTranslater from '@/othComponents/codeTemplate/BaseTranslater'
import { getProduct } from '@/api/product/product'
// import { getRelatedProduct } from '@/api/product'

export default {

  components : {
    RelatedProduct,
    ProductDesc,
    CompanyInfo,
    ProductInfo,
    ValidateSearchEdit,
    TemplatePictures,
    TemplateVideo,
    TemplateStylebox,
    TemplateHead,
    TemplateText,
    TemplateCarousel,
    CodeInformation,
    ProductImages,
    ProductPrice,
    ProductQualityGuaranteePeriod,
    ProductBarcode,
    BaseMusic,
    BottomNavigator,
    ThreeImg,
    BaseTranslater
  },
  props : {
    template : Object,
    sourceFrom : null
  },
  data() {
    const defaultProduct = {
      productName : '(请选择产品)'
    }
    const defaultCode = {
      productName : '(请选择产品)',
      categoryName : '(请选择产品)'
    }
    return {
      defaultIcon,
      shineId : 0,
      CONFIG,
      defaultProduct,
      product : defaultProduct,
      productImages : [],
      productAttrs : [],
      defaultCode,
      code : defaultCode,
      price : '',
      relatedProduct : []
    }
  },
  computed : {
    musicComp() {
      const list = this.template.items.filter( i => i.type === 'backMusic' )
      return list.length == 0 ? null : list[0]
    },
    translaterComp() {
      const list = this.template.items.filter( i => i.type === 'translater' )
      return list.length == 0 ? null : list[0]
    }
  },
  watch : {
    'template.productId' : {
      handler : async function() {
        const productId = this.template.productId
        if ( !productId ) {
          this.product = this.defaultProduct
          this.code = this.defaultCode
          this.productImages = []
          this.productAttrs = []
          return
        }
        getProduct( productId ).then( ( response ) => {
          const product = response.data
          this.product = product
          if ( product.photo ) {
            this.productImages = product.photo.split( ',' )
          }
          this.productAttrs = product.attributeList
          this.code = {
            productName : product.productName,
            categoryName : product.categoryName
          }
          this.price = product.salePrice
        } )
        // const product = await getJsonResult(`/product/product?id=${productId}`)
        //                 console.log(product)
      },
      immediate : true
    },
    'template.id' : {
      handler : async function() {
        // if (!this.template.id) {
        //     this.relatedProduct = []
        //     return
        // }
        // getRelatedProduct(this.template.id).then((response) => {
        //     if (response.data == []) {
        //         let relatedProduct = response
        //         for (let item of relatedProduct) {
        //             item.productImage = item.productImage || defaultIcon
        //         }
        //         this.relatedProduct = relatedProduct
        //     }
        // });
        // let relatedProduct = await getJsonResult(`/wx/item/relatedProduct?templateId=${this.template.id}`)

      }
    }
  },
  mounted() {
    // if (!this.sourceFrom || this.sourceFrom == undefined) {
    //     document.querySelector('.child-elements').onmouseover = function () {
    //         document.querySelector('.child-elements').style.color = 'blue'
    //     }
    // }

  },
  methods : {
    // 点击模块
    selectClick( item ) {
      this.shineId = item.id
    },
    // 选择项目的回调
    selectItem2( item, k ) {
      this.shineId = item.id
      this.$emit( 'selectItem2', { item, k } )
    },
    // 音乐点击
    musicClick : function( item ) {
      this.$emit( 'selectItem2', { item } )
    },
    remove( item ) {
      this.$confirm( '确定要删除该组件吗？', '提示', {
        confirmButtonText : '确定',
        cancelButtonText : '取消',
        closeOnClickModal : false,
        type : 'warning'
      } )
        .then( async() => {
          this.$emit( 'removeItem', item )
          this.$refs.homeSetting.asyncItemInfo( item )
        } )
        .catch( () => { } )
    },
    dragstartEvent( item, k ) {
      const self = this
      self.shineId = item.id
      self.$emit( 'selectItem2', { item, k } )
      self.$store.commit( 'setDragElement', event.currentTarget )
    },
    dragendEvent( ev ) {
      ev.preventDefault()
    },
    dragenterEvent( ev ) {
      const self = this
      if ( self.$store.state.dragElement != ev.target ) {
        console.log( 2132, self, self.$store.state.dragElement )
        ev.target.parentNode.insertBefore( self.$store.state.dragElement, ev.target )
        var list = ev.target.parentNode.children
        var listID = []
        for ( var q = 0; q < list.length; q++ ) {
          var a = list[q].id.slice( 8 )
          listID.push( a )
        }
        var items = self.template.items
        var newItems = []
        for ( var i = 0; i < listID.length; i++ ) {
          for ( var j = 0; j < items.length; j++ ) {
            if ( items[j].id == listID[i] ) {
              newItems.push( items[j] )
            }
          }
        }
        self.template.items = newItems
      }
    },
    dragleaveEvent( ev ) {
      const self = this
      if ( self.$store.state.dragElement != ev.target ) {
        if (
          self.lock &&
                    ( ev.target == ev.target.parentNode.lastElementChild || ev.target == ev.target.parentNode.lastChild )
        ) {
          ev.target.parentNode.appendChild( self.$store.state.dragElement )
          self.lock = false
          var list = ev.target.parentNode.children
          var listID = []
          for ( var q = 0; q < list.length; q++ ) {
            var a = list[q].id.slice( 8 )
            listID.push( a )
          }
          var items = self.template.items
          var newItems = []
          for ( var i = 0; i < listID.length; i++ ) {
            for ( var j = 0; j < items.length; j++ ) {
              if ( items[j].id == listID[i] ) {
                newItems.push( items[j] )
              }
            }
          }
          self.template.items = newItems
        } else {
          self.lock = true
        }
      }
    },
    dragoverEvent( ev ) {
      ev.preventDefault()
    },
    selectItem( id ) {
      this.shineId = id
      // document.getElementById('homeMsg-' + id).scrollIntoView()
    }
  }
}
