
import TemplateStyleboxItemView from './TemplateStyleboxItemView'
// import { getJson } from '@/utils/server'
import { queryWarrantyStatus } from '@/api/product'

export default {
  components : {
    TemplateStyleboxItemView
  },
  props : {
    config : Object,
    code : Object
  },
  data() {
    return {
      dialogVisible : false
    }
  },
  computed : {
    hasHref() {
      return !!this.config.href
    },
    hasMobile() {
      return !!this.config.kefuMobile
    },
    getMobile() {
      return this.config.kefuMobile
    },
    shouldPop() {
      return !!this.config.qrCodeImage
    },
    href() {
      return this.config.href || '#'
    },
    inWeixin() {
      return /MicroMessenger/i.test( navigator.userAgent )
    }
  },
  mounted() {
    this.initLocale()
  },
  methods : {
    initLocale() {
      var locale = sessionStorage.getItem( 'locale' )
      if ( locale == null || locale == '' ) {
        sessionStorage.setItem( 'locale', 'cn' )
      }
      this.$i18n.locale = sessionStorage.getItem( 'locale' )
    },
    imageSrc( src ) {
      return src + '?x-image-process=style/width-1024'
    },
    showImage() {
      this.dialogVisible = true
    },
    async openWarrent() {
      queryWarrantyStatus( encodeURIComponent( this.code.idisCode ) ).then( ( response ) => {
        const resp = response
        if ( resp.code !== 200 && resp.code != 200004 ) {
          this.$info.showTip( resp.message )
        } else if ( resp.code == 200004 ) {
          window.location.href = '/wx/warrantyWx?idisCode=' + encodeURIComponent( this.code.idisCode )
        } else {
          window.location.href = this.config.href
        }
      } )

      // const url = '/wx/queryWarrantyStatus?idisCode=' + encodeURIComponent(this.code.idisCode)
      // const resp = await getJson(url)
      // if (resp.code !== 200 && resp.code != 200004) {
      //     this.$info.showTip(resp.message)
      // } else if (resp.code == 200004) {
      //     window.location.href = '/wx/warrantyWx?idisCode=' + encodeURIComponent(this.code.idisCode)
      // } else {
      //     window.location.href = this.config.href
      // }
    },
    callMobile() {
      window.location.href = 'tel://' + this.config.kefuMobile
    },
    closeImage( e ) {
      this.dialogVisible = false
      e.stopPropagation()
    },
    showAlert( msg ) {
      alert( msg )
    },
    createHtml( config ) {
      let html = '<div class="stylebox-item" style="'
      if ( config.backimage ) {
        html += `background-image: url(${this.imageSrc( config.backimage )})`
      } else {
        html += `background-color: ${config.backcolor}`
      }
      html += '">'
      // if (config.fonticon) {
      //     html += `<i class="${config.fonticon} image" style="color: ${config.forecolor}"></i>`
      // }
      if ( config.imgIcon ) {
        html += `<img src="${config.imgIcon}" class="imgIcon" />`
      }
      if ( config.title ) {
        html += `<div class="title" style="text-align: ${config.titleAlign}; color: ${config.forecolor}">${config.title}</div>`
      }
      html += `</div>`
      html = `
            <template>
                <style>
                .stylebox-item {
                    margin: 2px;
                    height: 100px !important;
                    color: #ffffff;
                    width: 100%;

                    background-color: #ffffff;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;

                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }
                .image {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    font-size: 40px;
                    line-height: 40px;
                }
                .title {
                    margin: 2px 0 0 0;
                }
                .imgIcon {
                    border-radius: 50%;
                    width: 70px;
                    height: 70px;
                    display: block;
                }
                </style>
                ${html}
            </template>`

      return `
            <wx-open-launch-weapp
                username="${config.weixinWeappUserName}"
                path="${config.weixinWeappPath}"
                style="width: ${config.size * 33 - 1}vw; height: 100px;"
            >
                ${html}
            </wx-open-launch-weapp>
            `
    }
  }
}
