
import JsBarcode from 'jsbarcode'

export default {
  props : {
    item : Object,
    code : Object
  },
  watch : {
    'code.barCode' : {
      handler : function() {
        this.updateBarcode()
      }
    }
  },
  mounted() {
    this.updateBarcode()
  },
  methods : {
    updateBarcode() {
      JsBarcode( '.productInfo-barcode', this.code.barCode, {
        format : 'CODE128',
        text : this.code.barCode,
        displayValue : true,
        textPosition : 'bottom',
        width : 1,
        height : 30,
        fontSize : 12
      } )
    }
  }
}
