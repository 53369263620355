
import musicIcon from '@/assets/images/music.png'

export default {
  props : {
    item : Object
  },
  data() {
    return {
      musicIcon,
      audio : {
        // 该字段是音频是否处于播放状态的属性
        playing : false
      }
    }
  },
  methods : {
    asyncTitleInfo( item ) {
      console.log( item )
      this.item = item
      this.fileList = []
      if ( item.value.music != '' ) {
        this.fileList.push( { url : item.value.music, name : '' } )
      }
    },
    // 控制音频播放与暂停
    startPlayOrPause() {
      if ( this.item.value.music != '' ) {
        return this.audio.playing ? this.pause() : this.play()
      }
    },
    // 播放音频
    play() {
      this.$refs.audio.play()
    },
    // 暂停音频
    pause() {
      this.$refs.audio.pause()
    },
    // 当音频播放
    onPlay() {
      this.audio.playing = true
    },
    // 当音频暂停
    onPause() {
      this.audio.playing = false
    }
  }
}
