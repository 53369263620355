
export default {
  props : {
    item : Object
  },

  methods : {
    imageSrc( src ) {
      if ( src && src.startsWith( 'http' ) ) {
        return src + '?x-image-process=style/width-1024'
      }
      return src
    }
  }
}
