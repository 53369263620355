
export default {
  props : {
    item : Object
  },
  data() {
    return {
      imgs : [],
      maxZIndex : 0,
      startX : 0,
      endX : 0,
      deltaX : 0,
      max : 0,
      timer : null,
      keyDown : false,
      isAnimation : true,
      touchDown : false
    }
  },
  watch : {
    'item.value.images' : {
      handler( images ) {
        this.isAnimation = images.length > 0
        // console.log('change', images.length)
        this.imgs = images.map( i => ( {
          src : i,
          z : 0
        } ) )
        // 默认第一张置顶
        this.maxZIndex = 0
        this.setMax()
      },
      immediate : true
    }
  },
  mounted() {
    this.timer = setInterval( () => {
      if ( this.isAnimation ) {
        this.maxZIndex = ( this.maxZIndex + 1 ) % this.imgs.length
        this.setMax()
      }
    }, 200 )
  },
  methods : {
    touchstart( e ) {
      e.stopPropagation()
      e.preventDefault()
      this.startX = e.touches[0].clientX
      this.touchDown = true
      if ( this.timer != null ) {
        clearInterval( this.timer )
        this.timer = null
      }
    },
    touchmove( e ) {
      e.stopPropagation()
      e.preventDefault()
      if ( this.touchDown ) {
        this.endX = e.touches[0].clientX
        this.deltaX = this.endX - this.startX
        const len = this.imgs.length - 1
        if ( this.deltaX < 0 ) {
          if ( this.maxZIndex + 1 <= len ) {
            this.maxZIndex++
          } else {
            this.maxZIndex = 0
          }
        } else {
          if ( this.maxZIndex - 1 >= 0 ) {
            this.maxZIndex--
          } else {
            this.maxZIndex = len
          }
        }
        this.setMax()
        this.startX = this.endX
      }
    },
    touchend( e ) {
      e.stopPropagation()
      e.preventDefault()
      this.touchDown = false
    },
    change( e ) {
      e.stopPropagation()
      e.preventDefault()
      this.max = e.target.value
      this.maxZIndex = this.max
      this.setMax()
    },
    mouseout( e ) {
      e.stopPropagation()
      e.preventDefault()
      if ( this.timer ) {
        clearInterval( this.timer )
        this.timer = null
      }
      this.isAnimation = true
    },
    mouseover( e ) {
      e.stopPropagation()
      e.preventDefault()
      this.isAnimation = false
    },
    mousedown( e ) {
      e.stopPropagation()
      e.preventDefault()
      this.keyDown = true
      this.startX = e.pageX
    },
    mousemove( e ) {
      e.stopPropagation()
      e.preventDefault()
      if ( this.keyDown == true ) {
        this.endX = e.pageX
        this.deltaX = this.endX - this.startX
        const len = this.imgs.length - 1
        if ( this.deltaX < 0 ) {
          if ( this.maxZIndex + 1 <= len ) {
            this.maxZIndex++
          } else {
            this.maxZIndex = 0
          }
        } else {
          if ( this.maxZIndex - 1 >= 0 ) {
            this.maxZIndex--
          } else {
            this.maxZIndex = len
          }
        }
        this.setMax()
        this.startX = e.pageX
      }
    },
    mouseup( e ) {
      e.stopPropagation()
      e.preventDefault()
      this.keyDown = false
    },
    mouseleave( e ) {
      e.stopPropagation()
      e.preventDefault()
      this.keyDown = false
    },
    setMax() {
      if ( this.isAnimation ) {
        this.imgs.forEach( m => ( m.z = 0 ) )
        this.imgs[this.maxZIndex].z = 99
      }
    },
    imageSrc( src ) {
      if ( src.startsWith( 'http' ) ) {
        return src + '?x-image-process=style/width-1024'
      }
      return src
    }
  }
}
