

export default {
  props : {
    item : Object
  },
  data() {
    return {
      configArea : 'query',
      successTemplateList : [],
      failureTemplateList : []
    }
  },
  computed : {
    successTemplate() {
      if ( !this.item.value.successTemplate ) {
        return ''
      }
      const list = this.successTemplateList.filter( t => t.id === this.item.value.successTemplate )
      return ( list[0] || {} ).content
    },
    failureTemplate() {
      if ( !this.item.value.failureTemplate ) {
        return ''
      }
      const list = this.failureTemplateList.filter( t => t.id === this.item.value.failureTemplate )
      return ( list[0] || {} ).content
    }
  },
  watch : {
    configArea() {
      this.item.value.configArea = this.configArea
    }
  },
  async mounted() {
  }
}
