
export default {
  data() {
    const _this = this
    function titleValidator( idx ) {
      return [
        {
          validator : ( rule, value, callback ) => {
            const config = _this.item.value.rows[0][idx]
            if ( config.title && config.title.length > 6 ) {
              config.title = config.title.substring( 0, 6 )
              callback( new Error( '标题长度不要超过6个字符' ) )
            } else {
              callback()
            }
          },
          trigger : 'change'
        }
      ]
    }
    return {
      actionUrl : process.env.BASE_URL + '/file/upload/image?pageFunc=门户扫码模板宫格',
      bgColor : true,
      item : {
        value : {
          rows : [[]]
        }
      },
      rules : {
        title0 : titleValidator( 0 ),
        title1 : titleValidator( 1 ),
        title2 : titleValidator( 2 )
      },
      form : {},
      formStyle : 1,
      linkTypes : [
        { value : 'none', label : '无链接' },
        { value : 'normal', label : '自定义' },
        { value : 'weixinWeapp', label : '微信小程序' },
        // { value: 'suretyService', label: '售后' },
        // { value: 'warranty', label: '质保' },
        // { value: 'repair', label: '报修管理' },
        // { value: 'maintenanceHistory', label: '维保历史记录' },
        // { value: 'companyIntroduction', label: '公司介绍' },
        // { value: 'instruction', label: '使用说明' },
        { value : 'qrCodeScan', label : '关注微信 (上传关注二维码)' },
        { value : 'kefuMobile', label : '客服电话' },
        // { value: 'contactUs', label: '联系我们' },
        // { value: 'industrialMall', label: '工业商城' },
        { value : 'officialQuery', label : 'idis 官网查询' }
        // { value: 'redEnvelope', label: '跳转红包活动' }
      ],
      historyData : [],
      defaultStyles : [
        {
          title : '标题',
          href : 'https://',
          fonticon : 'menu',
          forecolor : '#FFFFFF',
          titleAlign : 'center',
          backcolor : '#004da1',
          linkType : 'normal'
        },
        {
          title : '标题',
          href : 'https://',
          fonticon : 'menu',
          forecolor : '#FFFFFF',
          titleAlign : 'center',
          backcolor : '#004da1',
          linkType : 'normal'
        },
        {
          title : '标题',
          href : 'https://',
          fonticon : 'menu',
          forecolor : '#FFFFFF',
          titleAlign : 'center',
          backcolor : '#004da1',
          linkType : 'normal'
        }
      ],
      bgHistory : [],
      iconHistory : []
    }
  },
  computed : {
    row() {
      return this.item.value.rows[0]
    }
  },
  watch : {
    formStyle( newValue ) {
      const newCellCount = this.getCellCount( newValue )
      const oldCellCount = this.row.length
      if ( oldCellCount < newCellCount ) {
        for ( let i = oldCellCount; i < newCellCount; i++ ) {
          this.row.push( Object.assign( {}, this.defaultStyles[i], this.historyData[i] ) )
        }
      } else if ( oldCellCount > newCellCount ) {
        for ( let i = newCellCount; i < oldCellCount; i++ ) {
          this.historyData[i] = this.row[i]
        }
        this.row.splice( newCellCount, oldCellCount - newCellCount )
      }

      if ( newValue === 1 ) {
        this.row[0].size = this.row[1].size = this.row[2].size = 1
      } else if ( newValue === 2 ) {
        this.row[0].size = 1
        this.row[1].size = 2
      } else if ( newValue === 3 ) {
        this.row[0].size = 2
        this.row[1].size = 1
      } else {
        this.row[0].size = 3
      }
    }
  },
  methods : {
    changeItem( item ) {
      if ( this.item === item ) {
        return
      }
      this.historyData = []
      this.item = item
      this.formStyle = this.getFormStyle( this.row )

      this.bgHistory = []
      this.iconHistory = []
      for ( let i = 0; i < 3; i++ ) {
        const currValue = this.row[i] || this.defaultStyles[i]
        if ( currValue.backcolor && currValue.backimage ) {
          currValue.backcolor = null
        }
        this.$set(
          this.bgHistory,
          i,
          Object.assign( {
            selectColor : !!currValue.backcolor,
            backimage : currValue.backimage,
            backcolor : currValue.backcolor
          } )
        )
        this.$set(
          this.iconHistory,
          i,
          Object.assign( {
            selectIcon : !!currValue.fonticon,
            fonticon : currValue.fonticon,
            imgIcon : currValue.imgIcon
          } )
        )
      }
    },
    getFormStyle( row ) {
      if ( row.length == 3 ) {
        return 1
      } else if ( row.length === 1 ) {
        return 4
      } else if ( row[0].size == 1 ) {
        return 2
      } else {
        return 3
      }
    },
    getCellCount( formStyle ) {
      if ( formStyle === 1 ) {
        return 3
      } else if ( formStyle === 4 ) {
        return 1
      } else {
        return 2
      }
    },
    uploadImg( box ) {
      return resp => {
        this.$set( box, 'backimage', resp.data )
      }
    },
    uploadImgIcon( box ) {
      return resp => {
        this.$set( box, 'imgIcon', resp.data )
      }
    },
    beforeUpload( file ) {
      const types = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/JPG', 'image/JPEG', 'image/PNG', 'image/GIF']
      const isImg = types.includes( file.type )
      const isLt2M = file.size / 1024 / 1024 < 2

      if ( !isLt2M ) {
        this.$message.error( '上传图片大小不能超过 2MB!' )
        return false
      }
      if ( !isImg ) {
        this.$message.error( '上传图片格式错误，仅限jpg，jpeg，png，gif格式' )
        return false
      }
      return isLt2M && isImg
    },
    uploadQrCodeScan( box ) {
      return resp => {
        console.log( 'uploaded', resp )
        this.$set( box, 'qrCodeImage', resp.data )
      }
    },
    onLinkChange( value, box ) {
      delete box.href
      delete box.kefuMobile
      delete box.weixinWeappUserName
      delete box.weixinWeappPath
      delete box.qrCodeImage
    },
    changeBg( value, i, box ) {
      const his = this.bgHistory[i]
      if ( value ) {
        his.backimage = box.backimage
        this.$set( box, 'backcolor', his.backcolor )
        this.$set( box, 'backimage', null )
      } else {
        his.backcolor = box.backcolor
        this.$set( box, 'backcolor', null )
        this.$set( box, 'backimage', his.backimage )
      }
    },
    changeIcon( value, i, box ) {
      const his = this.iconHistory[i]
      if ( value ) {
        his.imgIcon = box.imgIcon
        this.$set( box, 'imgIcon', null )
        this.$set( box, 'fonticon', his.fonticon )
      } else {
        his.fonticon = box.fonticon
        this.$set( box, 'imgIcon', his.imgIcon )
        this.$set( box, 'fonticon', null )
      }
    }
  }
}
