
import CONFIG from '@/othComponents/codeTemplate/config'

export default {
  name : 'Home',
  components : {},
  props : {
    template : Object
  },
  data() {
    return {
      settingSelect : [],
      settingEdit : [],
      // 选择项目的计数. key 是配置名称, value 是次数
      chooseItem : new Map(),
      iconStyleIcon : {
        stylebox : 'el-icon-s-grid',
        video : 'el-icon-video-play',
        pictures : 'el-icon-picture-outline',
        text : 'el-icon-document'
      }
    }
  },
  created() {
    for ( const key of Object.keys( CONFIG ) ) {
      const config = CONFIG[key]
      console.log( 'config>>>>>>>', config, this.template )
      if ( config.must ) {
        continue
      }
      if ( config.limit === 1 ) {
        config.isActive = false
        this.settingSelect.push( config )
      } else {
        config.isActive = false
        this.settingEdit.push( config )
      }
    }
  },
  mounted() {
    // 载入需要配置的选项, 必选的不显示, 限制为1 的显示为开关类型, 其余的显示为按钮类型
    // for (const key of Object.keys(CONFIG)) {
    //     const config = CONFIG[key]
    //     if (config.must) {
    //         continue
    //     }
    //     if (config.limit === 1) {
    //         config.isActive = false
    //         this.settingSelect.push(config)
    //     } else {
    //         this.settingEdit.push(config)
    //     }
    // }
  },
  methods : {
    // 修改时初始化数据
    syncSettingInfo( data ) {
      console.log( 'syncSettingInfosyncSettingInfo', this.settingSelect )
      data.items.map( k => {
        this.settingSelect.map( i => {
          if ( i.type == k.type ) {
            i.isActive = true
          }
        } )
        this.settingEdit.map( j => {
          if ( j.type == k.type ) {
            j.isActive = true
          }
        } )
      } )
      data.items.forEach( element => {
        if ( this.chooseItem.has( element.type ) ) {
          this.chooseItem.set( element.type, this.chooseItem.get( element.type ) + 1 )
        } else {
          this.chooseItem.set( element.type, 1 )
        }
      } )
      this.$forceUpdate()
    },
    // 更新数量 (添加/ 删除)
    updateItem( item, event ) {
      console.log( 4521515, item, '>>>', event )
      // 开关属性（移除还是添加）true 新增/ false 移除
      // let flag = event === undefined ? true : event
      let flag
      if ( item.limit === 1 ) {
        if ( !item.isActive ) {
          flag = event
        } else {
          flag = false
        }
      } else {
        flag = item.isActive ? true : event
      }

      const prop = { item : item, flag : flag }
      if ( !this.chooseItem.has( item.type ) ) {
        // 新增
        item.isActive = true
        this.chooseItem.set( item.type, 1 )
        this.$emit( 'updateItem', prop )
        this.$forceUpdate()
      } else {
        // 删除
        item.isActive = false
        if ( item && item.limit === 1 ) {
          // switch 类型判断是否需要删除
          if ( event && item.isActive ) {
            this.chooseItem.set( item.type, 1 )
          } else {
            this.chooseItem.delete( item.type )
          }

          this.$emit( 'updateItem', prop )
          this.$forceUpdate()
          return
        } else {
          item.isActive = true
        }

        if ( this.chooseItem.has( item.type ) && this.chooseItem.get( item.type ) < item.limit ) {
          this.chooseItem.set( item.type, this.chooseItem.get( item.type ) + 1 )
          this.$emit( 'updateItem', prop )
          this.$forceUpdate()
        } else {
          this.$message.error( '最多添加' + item.limit + '条' )
        }
      }
    },

    /**
         * 同步组件已选择信息delete
         */
    asyncItemInfo( item ) {
      this.settingSelect.map( i => {
        if ( i.type == item.type ) {
          i.isActive = false
        }
      } )
      if ( !this.chooseItem.has( item.type ) ) {
        this.$forceUpdate()
        return
      }
      if ( this.chooseItem.has( item.type ) && this.chooseItem.get( item.type ) > 1 ) {
        this.chooseItem.set( item.type, this.chooseItem.get( item.type ) - 1 )
      } else {
        this.chooseItem.delete( item.type )
      }
      this.$forceUpdate()
    }
  }
}
