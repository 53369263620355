
import SpecialTitle from './SpecialTitle'
export default {
  components : {
    SpecialTitle
  },
  props : ['list'],
  methods : {
    imageSrc( src ) {
      if ( typeof src === 'string' && src.startsWith( 'http' ) ) {
        return src + '?x-image-process=style/width-1024'
      }
      return src
    }
  }
}
