
import DefaultItemSetting from '@/othComponents/configuration/DefaultItemSetting'
import ImageItemSetting from '@/othComponents/configuration/ImageItemSetting'
import VideoItemSetting from '@/othComponents/configuration/VideoItemSetting'
import StyleboxItemSetting from '@/othComponents/configuration/StyleboxItemSetting'
import TextItemSetting from '@/othComponents/configuration/TextItemSetting'
import TitleItemSetting from '@/othComponents/configuration/TitleItemSetting'
import CarouselItemSetting from '@/othComponents/configuration/CarouselItemSetting'
import CompanyInfoSetting from '@/othComponents/configuration/CompanyInfoSetting'
import ProductInfoSetting from '@/othComponents/configuration/ProductInfoSetting'
import ValidateSetting from '@/othComponents/configuration/ValidateSetting'
import MusicItemSetting from '@/othComponents/configuration/MusicItemSetting'
import ThreeImgItemSetting from '@/othComponents/configuration/ThreeImgItemSetting'
import TranslaterItemSetting from '@/othComponents/configuration/TranslaterItemSetting'

const noSettingComp = [
  'relatedProduct',
  'productIntroduction',
  'productBarcode',
  'productQualityGuaranteePeriod',
  'productPrice',
  'codeInformation',
  'productImages',
  'weixinUserInfo'
]

export default {
  components : {
    DefaultItemSetting,
    ImageItemSetting,
    VideoItemSetting,
    StyleboxItemSetting,
    TextItemSetting,
    TitleItemSetting,
    CarouselItemSetting,
    CompanyInfoSetting,
    ProductInfoSetting,
    ValidateSetting,
    MusicItemSetting,
    ThreeImgItemSetting,
    TranslaterItemSetting
  },
  props : { sortList : Array },
  data() {
    const defaultItem = {
      value : {},
      visible : true,
      id : -1
    }
    return {
      activeName : 'first',
      compFlg : '',
      defaultItem,
      item : defaultItem
    }
  },

  mounted() {},
  methods : {
    switchingAttConf( item ) {
      this.compFlg = item.type && noSettingComp.indexOf( item.type ) < 0 ? item.type : ''
      if ( item.type == 'text' ) {
        this.$refs.textSetting.asyncTextInfo( item )
      } else if ( item.type === 'templateHead' ) {
        this.$refs.titleSetting.asyncTitleInfo( item )
      } else if ( item.type === 'backMusic' ) {
        this.$refs.musicSetting.asyncMusicInfo( item )
      } else if ( item.type === 'pictures' ) {
        this.$refs.imageSetting.asyncImageInfo( item )
      } else if ( item.type === 'stylebox' ) {
        this.$refs.styleboxSetting.changeItem( item )
      } else if ( item.type === 'video' ) {
        this.$refs.videoSetting.asyncVideoInfo( item )
      } else if ( item.type === 'carousel' ) {
        this.$refs.carouselSetting.asyncCarouselInfo( item )
      } else if ( item.type === 'threeImg' ) {
        this.$refs.threeImgItemSetting.syncInfo( item )
      }
      this.item = item
    },
    removeItem( item ) {
      console.log( this.item, '>>>>>', item, this.defaultItem )
      if ( this.item === item ) {
        this.item = this.defaultItem
        this.compFlg = ''
      }
    }
  }
}
